import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

class OpeningHoursDataService extends APIBaseFunctions {
  private cachedOpeningHoursDataSliceObj: APIDataSliceObj = {
    slicemode: 0,
    start: 0,
    limit: defaultAPILimit,
    page: 0,
    pagesize: 0,
    totalcount: 0
  }

  getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    const currentDataSliceObj = OpeningHoursDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedOpeningHoursDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      return http.get(`/aabningstiders?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    return http.get(`/aabningstiders?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  get (id: string) {
    return http.get(`/aabningstiders/${id}`)
  }

  getCount (extraCountParameter = '') {
    return http.get('/aabningstiders/count' + (extraCountParameter.length > 0 ? `?${extraCountParameter}` : ''))
  }

  create (data: any) {
    return http.post('/aabningstiders', data)
  }

  update (id: string, data: any) {
    return http.put(`/aabningstiders/${id}`, data)
  }

  delete (id: string) {
    return http.delete(`/aabningstiders/${id}`)
  }

  deleteAll () {
    return http.delete('/aabningstiders')
  }

  // No findByStatus or FindBySearchTerm function, consider making them??
}

export default new OpeningHoursDataService()
