export enum openingDaysFriendlyType {
  Mandag = <any>'Mandag',
  Tirsdag = <any>'Tirsdag',
  Onsdag = <any>'Onsdag',
  Torsdag = <any>'Torsdag',
  Fredag = <any>'Fredag',
  Loerdag = <any>'Lørdag',
  Soendag = <any>'Søndag'
}

export default interface SysOpeningHours {
  id: null
  aabningstider_status: number // This is used as a internal index to determine the order of the orders which should be displayed.
  aabningstider_aaben: string
  aabningstider_luk: string
  klubber_id: number
  aabningstider_dag: openingDaysFriendlyType
  aabningstider_kommentar: string
}
